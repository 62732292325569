<template>
  <b-modal
    ref="modal-new-loan-category"
    :title="'New Loan Category'"
    @hidden="$emit('hidden')"
    ok-title="Save"
    @ok.prevent="saveLoanCategory()"
    scrollable
    centered
  >
    <b-row>
      <b-col cols="12">
        <b-form-group label="Description" label-for="description">
          <b-form-input
            id="description"
            placeholder="Description"
            v-model="loanCategory.description"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import modalMixin from "@/mixins/modal";
import LoansService from "../services/loans.service";
export default {
  mixins: [modalMixin],
  data() {
    return {
      loanCategory: {
        description: null,
        created_by: this.$store.getters["auth/currentUser"].user_id,
        loan_category_id: null,
      },
    };
  },
  mounted() {
    this.toggleModal("modal-new-loan-category");
  },
  methods: {
    async saveLoanCategory() {
      try {
        this.addPreloader();
        await LoansService.saveLoanCategory(this.loanCategory);
        this.showSuccessSwal("Loan Category successfully");
        this.removePreloader();
        this.$emit("loan-category-saved");
      } catch (error) {
        this.showErrorSwal(error);
        this.removePreloader();
      }
    },
  },
};
</script>

<style>
</style>