<template>
  <b-modal
    ref="modal-new-external-user"
    :title="externalUserId ? 'Update External User' : 'New External User'"
    @hidden="$emit('hidden')"
    ok-title="Save"
    @ok.prevent="saveExternalUser()"
    scrollable
  >
    <b-row>
      <b-col cols="12" md="6">
        <b-form-group label="First Name" label-for="first-name">
          <b-form-input
            id="first-name"
            placeholder="First Name"
            v-model="externalUserObject.first_name"
          />
        </b-form-group>
      </b-col>
      <b-col cols="12" md="6">
        <b-form-group label="Last Name" label-for="last-name">
          <b-form-input
            id="last-name"
            placeholder="Last Name"
            v-model="externalUserObject.last_name"
          />
        </b-form-group>
      </b-col>
      <b-col cols="12" md="6">
        <b-form-group label="Email" label-for="email">
          <b-form-input
            id="email"
            placeholder="Email"
            v-model="externalUserObject.email"
          />
        </b-form-group>
      </b-col>
      <b-col cols="12" md="6">
        <b-form-group label="Phone" label-for="phone">
          <b-form-input
            id="phone"
            placeholder="Phone"
            v-model="externalUserObject.phone"
          />
        </b-form-group>
      </b-col>
      <b-col cols="12" md="6">
        <b-form-group label="Document type" label-for="document-type">
          <b-form-select
            v-model="externalUserObject.type_document"
            :options="documentTypes"
            text-field="description"
            value-field="id"
          ></b-form-select>
        </b-form-group>
      </b-col>
      <b-col cols="12" md="6">
        <b-form-group label="Document number" label-for="document-number">
          <b-form-input
            id="document-number"
            placeholder="Document number"
            v-model="externalUserObject.document_number"
          />
        </b-form-group>
      </b-col>
      <b-col cols="12">
        <b-form-group label="Address" label-for="address">
          <b-form-input
            id="address"
            placeholder="Address"
            v-model="externalUserObject.address"
          />
        </b-form-group>
      </b-col>
      <b-col cols="12">
        <b-form-group label="Files" label-for="files">
          <drag-and-drop
            id="files"
            v-model="files"
            :files-array="files"
            :files-to-show="externalUserObject.files"
            @delete-file="deleteFile"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import modalMixin from "@/mixins/modal";
import DragAndDrop from "@/views/commons/utilities/DragAndDrop";
import LoansService from "../services/loans.service";
export default {
  props: {
    externalUserId: {
      type: String,
      default: null,
    },
  },
  mixins: [modalMixin],
  data() {
    return {
      externalUserObject: {
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        type_document: "",
        document_number: "",
        address: "",
        files: [],
        created_by: this.$store.getters["auth/currentUser"].user_id,
        external_user_id: null,
      },
      documentTypes: [],
      files:[],
      filesToDelete: [],
    };
  },
  mounted() {
    this.toggleModal("modal-new-external-user");
    this.getDocumentTypes();
    if (this.externalUserId) {
      this.getExternalUser();
    }
  },
  components: {
    DragAndDrop,
  },
  methods: {
    async getDocumentTypes() {
      try {
        const response = await LoansService.getDocumentTypes();
        this.documentTypes = response;
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
    async getExternalUser() {
      try {
        const response = await LoansService.getExternalUser({external_user_id: this.externalUserId});
        this.externalUserObject = response[0];
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
    deleteFile(index, file){
        this.filesToDelete.push(file.id);
        this.externalUserObject.files.splice(index, 1);
    },
    async saveExternalUser() {
      try {
        this.addPreloader();
        const formData = new FormData();
        if (
          this.files !== null &&
          this.files !== ""
        ) {
          this.files.forEach((file) => {
            formData.append("images[]", file, file.name);
          });
        }
        this.externalUserObject.external_user_id = this.externalUserId;
        formData.append("header", JSON.stringify(this.externalUserObject));
        formData.append("filesToDelete", JSON.stringify(this.filesToDelete));
        await LoansService.saveExternalUser(formData);
        this.showSuccessSwal("External User saved successfully");
        this.removePreloader();
        this.$emit("external-user-saved");
      } catch (error) {
        this.showErrorSwal(error);
        this.removePreloader();
      }
    },
  },
};
</script>

<style>
</style>