<template>
  <b-modal
    ref="modal-view-external-user-files"
    :title="'Files'"
    @hidden="$emit('hidden')"
    scrollable
  >
    <view-files :files="externalUserFiles" />
    <template #modal-footer>
        <div></div>
    </template>
  </b-modal>
</template>

<script>
import modalMixin from "@/mixins/modal";
import LoansService from "../services/loans.service";
import ViewFiles from "@/views/commons/utilities/ViewFiles.vue";
export default {
  mixins: [modalMixin],
  props: {
    externalUserId: {
      type: String,
      required: true,
    },
  },
  components: {
    ViewFiles,
  },
  data(){
      return{
          externalUserFiles: [],
      }
  },
  mounted() {
    this.toggleModal("modal-view-external-user-files");
    this.getExternalUserFiles();
  },
  methods: {
    async getExternalUserFiles() {
      try {
        const files = await LoansService.getExternalUserFiles({
          external_user_id: this.externalUserId,
        });
        this.externalUserFiles = files;
      } catch (error) {
        this.showErrorSwal(error);
      }
    },
  },
};
</script>

<style>
</style>