<template>
  <div>
    <b-modal
      ref="modal-import-loan"
      size="lg"
      @hidden="$emit('hidden')"
      title="Import New Loan"
      :scrollable="isScrollable"
    >
      <ValidationObserver ref="form">
        <h4 class="title-card f-16">Information of Loan</h4>
        <b-row class="font-bureau-style">
          <b-col cols="12" md="5">
            <div class="form-group">
              <label class="font-bureau-style d-flex justify-content-between align-items-center">
                <span>User</span>
                <span v-if="loan.type == 2">
                  <feather-icon
                    icon="PlusIcon"
                    class="text-primary cursor-pointer"
                    @click="modalNewExternalUser = true"
                  />
                </span>
              </label>
              <ValidationProvider rules="required" v-slot="{ errors }">
                <v-select
                  :options="
                    loan.type == 1 ? optionsUsers : optionsUsersExternal
                  "
                  :reduce="(option) => option.id"
                  label="user_name"
                  @open="isScrollable = false"
                  @close="isScrollable = true"
                  v-model="loan.user_id"
                  class="border-hover bg-white-c"
                  :class="{ 'border border-danger': errors[0] }"
                  transition=""
                ></v-select>
              </ValidationProvider>
            </div>
          </b-col>
          <b-col cols="12" md="4">
            <div class="form-group">
              <label class="font-bureau-style">Creation Date</label>
              <ValidationProvider rules="required" v-slot="{ errors }">
                <b-form-datepicker
                  v-model="loan.creation_date"
                  placeholder="Select Date"
                  :date-format-options="formatDate"
                  class="border-hover bg-white-c"
                  :class="{ 'border border-danger': errors[0] }"
                />
              </ValidationProvider>
            </div>
          </b-col>
          <b-col cols="12" md="3">
            <div class="form-group">
              <label class="font-bureau-style">Type</label>
              <ValidationProvider rules="required" v-slot="{ errors }">
                <b-form-radio-group
                  v-model="loan.type"
                  :options="typeLoanOptions"
                  value-field="item"
                  text-field="name"
                  :class="{ 'border border-danger': errors[0] }"
                ></b-form-radio-group>
              </ValidationProvider>
            </div>
          </b-col>
        </b-row>
        <b-row class="font-bureau-style">
          <b-col cols="12" md="5">
            <div class="form-group">
              <label class="font-bureau-style">Amount Loan</label>
              <ValidationProvider
                rules="required|validate-amount"
                v-slot="{ errors }"
              >
                <money
                  v-model="loan.amount"
                  v-bind="{
                    decimal: '.',
                    thousands: ',',
                    prefix: loan.currency == 1 ? '$ ' : 'S/ ',
                    precision: 2,
                    masked: false,
                  }"
                  class="form-control border-hover bg-white-c"
                  :class="{
                    'border border-danger': errors[0] && vmoneyValidate,
                  }"
                ></money>
              </ValidationProvider>
            </div>
          </b-col>
          <b-col cols="12" md="4">
            <div class="form-group">
              <label class="font-bureau-style">Monthly Payment</label>
              <ValidationProvider
                rules="required|validate-amount"
                v-slot="{ errors }"
              >
                <money
                  v-model="loan.monthly_payment"
                  v-bind="{
                    decimal: '.',
                    thousands: ',',
                    prefix: loan.currency == 1 ? '$ ' : 'S/ ',
                    precision: 2,
                    masked: false,
                  }"
                  class="form-control border-hover bg-white-c"
                  :class="{
                    'border border-danger': errors[0] && vmoneyValidate,
                  }"
                ></money>
              </ValidationProvider>
            </div>
          </b-col>
          <b-col cols="12" md="3">
            <div class="form-group">
              <label class="font-bureau-style">Interest</label>
              <ValidationProvider rules="required" v-slot="{ errors }">
                <b-form-input
                  class="border-hover bg-white-c"
                  v-model="loan.interest"
                  type="number"
                  :class="{ 'border border-danger': errors[0] }"
                  step="0.01"
                  min="0.00"
                  max="100.00"
                />
              </ValidationProvider>
            </div>
          </b-col>
          <template v-if="loan.type == 2">
            <b-col cols="12" md="5">
              <div class="form-group">
                <label class="font-bureau-style">Currency</label>
                <ValidationProvider rules="required" v-slot="{ errors }">
                  <b-form-radio-group
                    v-model="loan.currency"
                    :options="currencyLoanOptions"
                    value-field="item"
                    text-field="name"
                    :class="{ 'border border-danger': errors[0] }"
                    style="margin-top: 8px"
                  ></b-form-radio-group>
                </ValidationProvider>
              </div>
            </b-col>
            <b-col cols="12" md="6">
              <div class="form-group">
                <label
                  class="
                    font-bureau-style
                    d-flex
                    justify-content-between
                    align-items-center
                  "
                >
                  <span>Category</span>
                  <span>
                    <feather-icon
                      icon="PlusIcon"
                      class="text-primary cursor-pointer"
                      @click="modalNewLoanCategory = true"
                    />
                  </span>
                </label>
                <ValidationProvider rules="required" v-slot="{ errors }">
                  <v-select
                    :options="categories"
                    :reduce="(option) => option.id"
                    label="description"
                    @open="isScrollable = false"
                    @close="isScrollable = true"
                    v-model="loan.category_id"
                    class="border-hover bg-white-c"
                    :class="{ 'border border-danger': errors[0] }"
                    transition=""
                  ></v-select>
                </ValidationProvider>
              </div>
            </b-col>
          </template>
        </b-row>
        <hr />

        <h4 class="title-card mt-3 f-16">
          Payments
          <b-button
            size="sm"
            class="btn-icon rounded-circle"
            variant="info"
            @click="addPayment"
            title="Add Payment"
          >
            <feather-icon icon="PlusIcon" />
          </b-button>
        </h4>
        <b-row
          class="font-bureau-style"
          v-for="(payment, index) in loan.payments"
          :key="payment.id"
        >
          <b-col cols="12" md="5">
            <div class="form-group">
              <label class="font-bureau-style">Amount</label>
              <ValidationProvider
                :rules="
                  'required|validate-amount' +
                  (index + 1 != countPayments
                    ? `|min_value:${loan.monthly_payment}`
                    : '')
                "
                v-slot="{ errors }"
              >
                <money
                  v-model="payment.amount"
                  v-bind="{
                    decimal: '.',
                    thousands: ',',
                    prefix: loan.currency == 1 ? '$ ' : 'S/ ',
                    precision: 2,
                    masked: false,
                  }"
                  class="form-control border-hover bg-white-c"
                  :class="{
                    'border border-danger': errors[0] && vmoneyValidate,
                  }"
                ></money>
                <span v-if="errors[0] == 'is min'">{{ errors[0] }}</span>
              </ValidationProvider>
            </div>
          </b-col>
          <b-col cols="12" md="5">
            <div class="form-group">
              <label class="font-bureau-style">Date</label>
              <ValidationProvider rules="required" v-slot="{ errors }">
                <b-form-datepicker
                  v-model="payment.date"
                  placeholder="Select Date"
                  :date-format-options="formatDate"
                  class="border-hover bg-white-c"
                  :class="{ 'border border-danger': errors[0] }"
                />
              </ValidationProvider>
            </div>
          </b-col>
          <b-col cols="12" md="2" class="mt-auto mb-1">
            <b-button
              size="sm"
              class="btn-icon rounded-circle"
              variant="info"
              @click="removePayment(index)"
              title="Remove Payment"
            >
              <feather-icon icon="MinusIcon" />
            </b-button>
          </b-col>
        </b-row>
        <hr />

        <h4 class="title-card mt-3 f-16">
          Charges
          <b-button
            @click="addCharge"
            title="Add Charge"
            size="sm"
            class="btn-icon rounded-circle"
            variant="info"
          >
            <feather-icon icon="PlusIcon" />
          </b-button>
        </h4>
        <b-row
          class="font-bureau-style"
          v-for="(charge, index) in loan.charges"
          :key="index"
        >
          <b-col cols="12" md="3">
            <div class="form-group">
              <label class="font-bureau-style">Type</label>
              <ValidationProvider rules="required" v-slot="{ errors }">
                <b-form-select
                  v-model="charge.type"
                  :options="optionsType"
                  class="
                    select-icon-none
                    font-bureau-style
                    border-hover
                    bg-white-c
                  "
                  :class="{ 'border-error-sn': errors[0] }"
                ></b-form-select>
              </ValidationProvider>
            </div>
          </b-col>
          <b-col cols="12" md="2">
            <div class="form-group">
              <label class="font-bureau-style">Amount</label>
              <ValidationProvider
                rules="required|validate-amount"
                v-slot="{ errors }"
              >
                <money
                  v-model="charge.amount"
                  v-bind="{
                    decimal: '.',
                    thousands: ',',
                    prefix: loan.currency == 1 ? '$ ' : 'S/ ',
                    precision: 2,
                    masked: false,
                  }"
                  class="form-control border-hover bg-white-c"
                  :class="{
                    'border border-danger': errors[0] && vmoneyValidate,
                  }"
                ></money>
              </ValidationProvider>
            </div>
          </b-col>
          <b-col cols="12" md="5">
            <div class="form-group">
              <label class="font-bureau-style">Date</label>
              <ValidationProvider rules="required" v-slot="{ errors }">
                <b-form-datepicker
                  v-model="charge.date"
                  placeholder="Select Date"
                  :date-format-options="formatDate"
                  class="border-hover bg-white-c"
                  :class="{ 'border border-danger': errors[0] }"
                />
              </ValidationProvider>
            </div>
          </b-col>
          <b-col cols="12" md="2" class="mt-auto mb-1">
            <b-button
              size="sm"
              class="btn-icon rounded-circle"
              variant="info"
              @click="removeCharge(index)"
              title="Remove Charge"
            >
              <feather-icon icon="MinusIcon" />
            </b-button>
          </b-col>
        </b-row>
        <hr />
      </ValidationObserver>

      <template #modal-footer>
        <b-button variant="primary" @click="saveLoan" title="Save Loan">
          <feather-icon icon="SaveIcon" class="mr-1" /> Save Loan
        </b-button>
      </template>
    </b-modal>
    <modal-new-external-user
      v-if="modalNewExternalUser"
      @hidden="modalNewExternalUser = false"
      @external-user-saved="refreshExternalUsers"
    ></modal-new-external-user>
    <modal-new-loan-category
      v-if="modalNewLoanCategory"
      @hidden="modalNewLoanCategory = false"
      @loan-category-saved="refreshLoanCategories"
    ></modal-new-loan-category>
  </div>
</template>

<script>
import GlobalService from "@/views/services/global.service";
import LoansService from "../services/loans.service";
import vSelect from "vue-select";
import modalMixin from "@/mixins/modal.js";
import { mapGetters } from "vuex";
import ModalNewExternalUser from './ModalNewExternalUser.vue';
import ModalNewLoanCategory from './ModalNewLoanCategory.vue';
export default {
  mixins: [modalMixin],
  components: {
    vSelect,
    ModalNewExternalUser,
    ModalNewLoanCategory,
  },
  data() {
    return {
      loan: {
        amount: 0,
        monthly_payment: 0,
        interest: 0,
        user_id: null,
        user_import: null,
        payments: [],
        charges: [],
        balance: 0,
        creation_date: null,
        type: 1,
        currency: 1,
        category_id: null,
      },
      formatDate: {
        year: "numeric",
        month: "numeric",
        day: "numeric",
      },
      optionsUsers: [],
      optionsUsersExternal: [],
      optionsType: [
        { text: "Interest", value: 1 },
        { text: "Penalty", value: 2 },
        { text: "Select Type", value: null, disabled: true },
      ],
      vmoneyValidate: false,
      isScrollable: true,
      typeLoanOptions: [
        { item: 1, name: "Corporate" },
        { item: 2, name: "External" },
      ],
      currencyLoanOptions: [
        { item: 1, name: "DOLLAR ($)" },
        { item: 2, name: "SOLES (S/)" },
      ],
      categories: [],
      modalNewExternalUser: false,
      modalNewLoanCategory: false,
    };
  },
  mounted() {
    this.toggleModal("modal-import-loan");
    this.getAllLoanCategories();
  },
  computed: {
    isUserSelected() {
      return this.loan.user_id !== null;
    },
    balance() {
      let sum_payments = this.loan.payments.reduce((acc, cur) => {
        return acc + cur.amount;
      }, 0);
      let sum_charges = this.loan.charges.reduce((acc, cur) => {
        return acc + cur.amount;
      }, 0);
      return this.loan.amount - sum_payments + sum_charges;
    },
    isCorrectLastPayment() {
      let last_payment = this.loan.payments[this.loan.payments.length - 1];
      let count_payments = this.loan.payments.length;
      if (count_payments === 0) {
        return false;
      } else {
        return last_payment.amount < this.loan.monthly_payment;
      }
    },
    countPayments() {
      return this.loan.payments.length;
    },
    isAtLeastOnePayment() {
      return this.loan.payments.length > 0;
    },
    ...mapGetters({
      currentUser: "auth/currentUser",
    }),
  },
  methods: {
    async getAllLoanCategories() {
      let response = await LoansService.getAllLoanCategories();
      this.categories = response;
    },
    async getUsers() {
      let response = await GlobalService.getAllUsers();
      this.optionsUsers = response;
    },
    async getExternalUsers() {
      let response = await LoansService.getExternalUsersForSelect();
      this.optionsUsersExternal = response;
    },
    closeModal() {
      this.$emit("click", false);
    },
    addPayment() {
      this.loan.payments.push({
        amount: 0,
        date: "",
      });
    },
    removePayment(index) {
      this.loan.payments.splice(index, 1);
    },
    addCharge() {
      this.loan.charges.push({
        type: null,
        amount: 0,
        date: "",
      });
    },
    removeCharge(index) {
      this.loan.charges.splice(index, 1);
    },
    resetLoanInformation() {
      this.loan.amount = 0;
      this.loan.monthly_payment = 0;
      this.loan.interest = 0;
      this.loan.user_id = null;
      this.loan.user_import = null;
      this.loan.payments = [];
      this.loan.charges = [];
      this.loan.balance = 0;
      this.loan.creation_date = null;
      this.loan.currency = 1;
      this.loan.category_id = null;
      this.$refs.form.reset();
    },
    async saveLoan() {
      this.loan.user_import = this.currentUser.user_id;
      this.loan.balance = this.balance;
      this.vmoneyValidate = true;
      const success = await this.$refs.form.validate();
      if (!success) {
        return;
      }
      const result = await this.showConfirmSwal();
      if (result.value) {
        this.addPreloader();
        try {
          await LoansService.importLoan(this.loan);
          this.closeModal();
          this.showSuccessSwal("success, Loan imported successfully");
          this.removePreloader();
        } catch (error) {
          this.closeModal();
          this.showErrorSwal();
          this.removePreloader();
        }
      }
    },
    refreshExternalUsers(){
      this.modalNewExternalUser = false;
      this.getExternalUsers();
    },
    refreshLoanCategories(){
      this.modalNewLoanCategory = false;
      this.getAllLoanCategories();
    }
  },
  created() {
    this.getUsers();
    this.getExternalUsers();
  },
  watch: {
    //validate interest is over 100
    loan: {
      handler: function (val) {
        if (val.interest > 100) {
          this.loan.interest = 100;
        }
      },
      deep: true,
    },
    "loan.type"() {
      this.resetLoanInformation();
    },
  },
};
</script>
<style scoped>
.v-select {
  width: 100% !important;
}

[type="number"] {
  width: 100%;
}
</style>

