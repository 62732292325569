<template>
  <div>
    <b-modal
      ref="modal-list-external-users"
      size="xl"
      title="External Users"
      hide-footer
      modal-class="modal-primary"
      @hidden="hideModal()"
      scrollable
    >
      <b-row>
        <b-col cols="12" class="mb-1">
          <div class="d-flex justify-content-end align-items-center">
            <b-button variant="primary" @click="modalNewExternalUser = true">
              New External User
            </b-button>
          </div>
        </b-col>
        <b-col cols="12">
          <filter-slot
            :filter="[]"
            :filter-principal="filterPrincipal"
            :total-rows="totalData"
            :paginate="paginate"
            :start-page="startPage"
            :to-page="toPage"
            :send-multiple-sms="false"
            @reload="$refs.refExternalUsersList.refresh()"
          >
            <template #table>
              <b-table
                ref="refExternalUsersList"
                class="position-relative"
                :items="myProvider"
                :has-provider="true"
                :fields="arrayColumns"
                primary-key="id"
                table-class="text-nowrap"
                responsive="sm"
                show-empty
                small
                hover
                no-border-collapse
                sticky-header="65vh"
                :busy="isBusy"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :current-page="paginate.currentPage"
                :per-page="paginate.perPage"
                :filter="searchInput"
                no-provider-filtering
                thead-class="text-center"
                tbody-class="text-center"
              >
                <template #table-busy>
                  <div class="text-center text-primary my-2">
                    <b-spinner class="align-middle mr-1" />
                    <strong>Loading ...</strong>
                  </div>
                </template>
                <template #cell(files)="data">
                  <feather-icon
                    icon="FileIcon"
                    class="cursor-pointer"
                    :class="[data.item.file_counter > 0 ? 'text-primary' : '']"
                    size="17"
                    @click="showFiles(data.item.id)"
                  />
                </template>
                <template #cell(actions)="data">
                  <feather-icon
                    icon="Edit2Icon"
                    class="cursor-pointer text-warning mr-1"
                    size="17"
                    @click="editExternalUser(data.item.id)"
                  />
                  <feather-icon
                    icon="TrashIcon"
                    class="cursor-pointer text-danger"
                    size="17"
                    @click="deleteExternalUser(data.item.id)"
                  />
                </template>
              </b-table>
            </template>
          </filter-slot>
        </b-col>
      </b-row>
    </b-modal>
    <modal-new-external-user
      v-if="modalNewExternalUser"
      @hidden="onModalHide()"
      :external-user-id="selectedExternalUserId"
      @external-user-saved="refreshExternalUsers"
    ></modal-new-external-user>
    <modal-view-external-user-files
      :external-user-id="selectedExternalUserId"
      @hidden="onModalHide()"
      v-if="modalExternalUserFiles"
    />
  </div>
</template>

<script>
import LoansService from "@/views/commons/components/loans/services/loans.service";
import { mapGetters } from "vuex";
import modalMixin from "@/mixins/modal";
import ModalNewExternalUser from "./ModalNewExternalUser.vue";
import ModalViewExternalUserFiles from "./ModalViewExternalUserFiles.vue";
export default {
  name: "ModalListExternalUsers",
  props: {},
  mixins: [modalMixin],
  components: {
    ModalNewExternalUser,
    ModalViewExternalUserFiles,
  },
  mounted() {
    this.toggleModal("modal-list-external-users");
  },
  data() {
    return {
      sortBy: "first_name",
      sortDesc: true,
      paginate: {
        currentPage: 1,
        perPage: 50,
      },
      externalUsers: [],
      arrayColumns: [
        {
          key: "first_name",
          label: "First Name",
        },
        {
          key: "last_name",
          label: "Last Name",
        },
        {
          key: "email",
          label: "Email",
        },
        {
          key: "phone",
          label: "Phone",
        },
        {
          key: "type_document",
          label: "Document type",
        },
        {
          key: "document_number",
          label: "Document Number",
        },
        {
          key: "address",
          label: "Address",
        },
        {
          key: "files",
          label: "Files",
        },
        {
          key: "actions",
          label: "Actions",
        },
      ],
      searchInput: "",
      orderby: "",
      order: "",
      startPage: null,
      endPage: null,
      totalData: null,
      perPage: 50,
      nextPage: "",
      currentPage: 1,
      toPage: null,
      isBusy: false,
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Search User...",
        model: "",
      },
      filters: [
        {
          type: "datepicker",
          cols: 6,
          margin: true,
          showLabel: true,
          label: "From",
          placeholder: "Date",
          class: "font-small-3",
          model: null,
          locale: "en",
          dateFormatOptions: {
            year: "numeric",
            month: "numeric",
            day: "numeric",
          },
        },
        {
          type: "datepicker",
          cols: 6,
          margin: true,
          showLabel: true,
          label: "To",
          placeholder: "Date",
          class: "font-small-3",
          model: null,
          locale: "en",
          dateFormatOptions: {
            year: "numeric",
            month: "numeric",
            day: "numeric",
          },
        },
        {
          type: "select",
          cols: 12,
          margin: true,
          showLabel: true,
          label: "Status",
          placeholder: "",
          class: "font-small-3",
          model: null,
          options: [
            { value: 0, label: "All" },
            { value: 1, label: "Pending" },
            { value: 4, label: "Disapproved" },
            { value: 6, label: "Approved" },
            { value: 2, label: "Finished" },
          ],
          reduce: "value",
          selectText: "label",
        },
      ],
      modalNewExternalUser: false,
      selectedExternalUserId: null,
      modalExternalUserFiles: false,
    };
  },
  computed: {
    ...mapGetters({
      bigWindow: "app/bigWindow",
      currentUser: "auth/currentUser",
    }),
  },
  methods: {
    hideModal() {
      this.$emit("hidden");
    },
    async myProvider(ctx) {
      try {
        this.isBusy = true;
        const params = {
          page: ctx.currentPage,
          campo: this.filterPrincipal.model,
          perpage: this.paginate.perPage,
          orderby: ctx.sortBy,
          order: ctx.sortDesc ? "desc" : "asc",
        };
        const response = await LoansService.getExternalUsers(params);
        this.externalUsers = response.data;
        this.startPage = response.from;
        this.paginate.currentPage = response.current_page;
        this.paginate.perPage = response.per_page;
        this.nextPage = this.startPage + 1;
        this.endPage = response.last_page;
        this.totalData = response.total;
        this.toPage = response.to;
        this.isBusy = false;
        return this.externalUsers;
      } catch (error) {
        this.showErrorSwal();
        this.isBusy = false;
        return [];
      }
    },
    refreshExternalUsers() {
      this.modalNewExternalUser = false;
      this.selectedExternalUserId = null;
      this.$refs.refExternalUsersList.refresh();
    },
    editExternalUser(externalUserId) {
      this.selectedExternalUserId = externalUserId;
      this.modalNewExternalUser = true;
    },
    onModalHide() {
      this.modalNewExternalUser = false;
      this.modalExternalUserFiles = false;
      this.selectedExternalUserId = null;
    },
    showFiles(externalUserId){
      this.selectedExternalUserId = externalUserId;
      this.modalExternalUserFiles = true;
    },
    async deleteExternalUser(externalUserId) {
      try {
        let params = {
          external_user_id: externalUserId,
        };
        const result = await this.showConfirmSwal();
        if (result.value) {
          await LoansService.deleteExternalUser(params);
          this.showSuccessSwal();
          this.refreshExternalUsers();
        }
      } catch (error) {
        this.showErrorSwal();
      }
    },
  },
};
</script>
