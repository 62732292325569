var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{ref:"modal-import-loan",attrs:{"size":"lg","title":"Import New Loan","scrollable":_vm.isScrollable},on:{"hidden":function($event){return _vm.$emit('hidden')}},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('b-button',{attrs:{"variant":"primary","title":"Save Loan"},on:{"click":_vm.saveLoan}},[_c('feather-icon',{staticClass:"mr-1",attrs:{"icon":"SaveIcon"}}),_vm._v(" Save Loan ")],1)]},proxy:true}])},[_c('ValidationObserver',{ref:"form"},[_c('h4',{staticClass:"title-card f-16"},[_vm._v("Information of Loan")]),_c('b-row',{staticClass:"font-bureau-style"},[_c('b-col',{attrs:{"cols":"12","md":"5"}},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"font-bureau-style d-flex justify-content-between align-items-center"},[_c('span',[_vm._v("User")]),(_vm.loan.type == 2)?_c('span',[_c('feather-icon',{staticClass:"text-primary cursor-pointer",attrs:{"icon":"PlusIcon"},on:{"click":function($event){_vm.modalNewExternalUser = true}}})],1):_vm._e()]),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{staticClass:"border-hover bg-white-c",class:{ 'border border-danger': errors[0] },attrs:{"options":_vm.loan.type == 1 ? _vm.optionsUsers : _vm.optionsUsersExternal,"reduce":function (option) { return option.id; },"label":"user_name","transition":""},on:{"open":function($event){_vm.isScrollable = false},"close":function($event){_vm.isScrollable = true}},model:{value:(_vm.loan.user_id),callback:function ($$v) {_vm.$set(_vm.loan, "user_id", $$v)},expression:"loan.user_id"}})]}}])})],1)]),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"font-bureau-style"},[_vm._v("Creation Date")]),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-datepicker',{staticClass:"border-hover bg-white-c",class:{ 'border border-danger': errors[0] },attrs:{"placeholder":"Select Date","date-format-options":_vm.formatDate},model:{value:(_vm.loan.creation_date),callback:function ($$v) {_vm.$set(_vm.loan, "creation_date", $$v)},expression:"loan.creation_date"}})]}}])})],1)]),_c('b-col',{attrs:{"cols":"12","md":"3"}},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"font-bureau-style"},[_vm._v("Type")]),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-radio-group',{class:{ 'border border-danger': errors[0] },attrs:{"options":_vm.typeLoanOptions,"value-field":"item","text-field":"name"},model:{value:(_vm.loan.type),callback:function ($$v) {_vm.$set(_vm.loan, "type", $$v)},expression:"loan.type"}})]}}])})],1)])],1),_c('b-row',{staticClass:"font-bureau-style"},[_c('b-col',{attrs:{"cols":"12","md":"5"}},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"font-bureau-style"},[_vm._v("Amount Loan")]),_c('ValidationProvider',{attrs:{"rules":"required|validate-amount"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('money',_vm._b({staticClass:"form-control border-hover bg-white-c",class:{
                  'border border-danger': errors[0] && _vm.vmoneyValidate,
                },model:{value:(_vm.loan.amount),callback:function ($$v) {_vm.$set(_vm.loan, "amount", $$v)},expression:"loan.amount"}},'money',{
                  decimal: '.',
                  thousands: ',',
                  prefix: _vm.loan.currency == 1 ? '$ ' : 'S/ ',
                  precision: 2,
                  masked: false,
                },false))]}}])})],1)]),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"font-bureau-style"},[_vm._v("Monthly Payment")]),_c('ValidationProvider',{attrs:{"rules":"required|validate-amount"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('money',_vm._b({staticClass:"form-control border-hover bg-white-c",class:{
                  'border border-danger': errors[0] && _vm.vmoneyValidate,
                },model:{value:(_vm.loan.monthly_payment),callback:function ($$v) {_vm.$set(_vm.loan, "monthly_payment", $$v)},expression:"loan.monthly_payment"}},'money',{
                  decimal: '.',
                  thousands: ',',
                  prefix: _vm.loan.currency == 1 ? '$ ' : 'S/ ',
                  precision: 2,
                  masked: false,
                },false))]}}])})],1)]),_c('b-col',{attrs:{"cols":"12","md":"3"}},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"font-bureau-style"},[_vm._v("Interest")]),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-input',{staticClass:"border-hover bg-white-c",class:{ 'border border-danger': errors[0] },attrs:{"type":"number","step":"0.01","min":"0.00","max":"100.00"},model:{value:(_vm.loan.interest),callback:function ($$v) {_vm.$set(_vm.loan, "interest", $$v)},expression:"loan.interest"}})]}}])})],1)]),(_vm.loan.type == 2)?[_c('b-col',{attrs:{"cols":"12","md":"5"}},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"font-bureau-style"},[_vm._v("Currency")]),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-radio-group',{class:{ 'border border-danger': errors[0] },staticStyle:{"margin-top":"8px"},attrs:{"options":_vm.currencyLoanOptions,"value-field":"item","text-field":"name"},model:{value:(_vm.loan.currency),callback:function ($$v) {_vm.$set(_vm.loan, "currency", $$v)},expression:"loan.currency"}})]}}],null,false,4104384137)})],1)]),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"\n                  font-bureau-style\n                  d-flex\n                  justify-content-between\n                  align-items-center\n                "},[_c('span',[_vm._v("Category")]),_c('span',[_c('feather-icon',{staticClass:"text-primary cursor-pointer",attrs:{"icon":"PlusIcon"},on:{"click":function($event){_vm.modalNewLoanCategory = true}}})],1)]),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('v-select',{staticClass:"border-hover bg-white-c",class:{ 'border border-danger': errors[0] },attrs:{"options":_vm.categories,"reduce":function (option) { return option.id; },"label":"description","transition":""},on:{"open":function($event){_vm.isScrollable = false},"close":function($event){_vm.isScrollable = true}},model:{value:(_vm.loan.category_id),callback:function ($$v) {_vm.$set(_vm.loan, "category_id", $$v)},expression:"loan.category_id"}})]}}],null,false,3085652614)})],1)])]:_vm._e()],2),_c('hr'),_c('h4',{staticClass:"title-card mt-3 f-16"},[_vm._v(" Payments "),_c('b-button',{staticClass:"btn-icon rounded-circle",attrs:{"size":"sm","variant":"info","title":"Add Payment"},on:{"click":_vm.addPayment}},[_c('feather-icon',{attrs:{"icon":"PlusIcon"}})],1)],1),_vm._l((_vm.loan.payments),function(payment,index){return _c('b-row',{key:payment.id,staticClass:"font-bureau-style"},[_c('b-col',{attrs:{"cols":"12","md":"5"}},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"font-bureau-style"},[_vm._v("Amount")]),_c('ValidationProvider',{attrs:{"rules":'required|validate-amount' +
                (index + 1 != _vm.countPayments
                  ? ("|min_value:" + (_vm.loan.monthly_payment))
                  : '')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('money',_vm._b({staticClass:"form-control border-hover bg-white-c",class:{
                  'border border-danger': errors[0] && _vm.vmoneyValidate,
                },model:{value:(payment.amount),callback:function ($$v) {_vm.$set(payment, "amount", $$v)},expression:"payment.amount"}},'money',{
                  decimal: '.',
                  thousands: ',',
                  prefix: _vm.loan.currency == 1 ? '$ ' : 'S/ ',
                  precision: 2,
                  masked: false,
                },false)),(errors[0] == 'is min')?_c('span',[_vm._v(_vm._s(errors[0]))]):_vm._e()]}}],null,true)})],1)]),_c('b-col',{attrs:{"cols":"12","md":"5"}},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"font-bureau-style"},[_vm._v("Date")]),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-datepicker',{staticClass:"border-hover bg-white-c",class:{ 'border border-danger': errors[0] },attrs:{"placeholder":"Select Date","date-format-options":_vm.formatDate},model:{value:(payment.date),callback:function ($$v) {_vm.$set(payment, "date", $$v)},expression:"payment.date"}})]}}],null,true)})],1)]),_c('b-col',{staticClass:"mt-auto mb-1",attrs:{"cols":"12","md":"2"}},[_c('b-button',{staticClass:"btn-icon rounded-circle",attrs:{"size":"sm","variant":"info","title":"Remove Payment"},on:{"click":function($event){return _vm.removePayment(index)}}},[_c('feather-icon',{attrs:{"icon":"MinusIcon"}})],1)],1)],1)}),_c('hr'),_c('h4',{staticClass:"title-card mt-3 f-16"},[_vm._v(" Charges "),_c('b-button',{staticClass:"btn-icon rounded-circle",attrs:{"title":"Add Charge","size":"sm","variant":"info"},on:{"click":_vm.addCharge}},[_c('feather-icon',{attrs:{"icon":"PlusIcon"}})],1)],1),_vm._l((_vm.loan.charges),function(charge,index){return _c('b-row',{key:index,staticClass:"font-bureau-style"},[_c('b-col',{attrs:{"cols":"12","md":"3"}},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"font-bureau-style"},[_vm._v("Type")]),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-select',{staticClass:"\n                  select-icon-none\n                  font-bureau-style\n                  border-hover\n                  bg-white-c\n                ",class:{ 'border-error-sn': errors[0] },attrs:{"options":_vm.optionsType},model:{value:(charge.type),callback:function ($$v) {_vm.$set(charge, "type", $$v)},expression:"charge.type"}})]}}],null,true)})],1)]),_c('b-col',{attrs:{"cols":"12","md":"2"}},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"font-bureau-style"},[_vm._v("Amount")]),_c('ValidationProvider',{attrs:{"rules":"required|validate-amount"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('money',_vm._b({staticClass:"form-control border-hover bg-white-c",class:{
                  'border border-danger': errors[0] && _vm.vmoneyValidate,
                },model:{value:(charge.amount),callback:function ($$v) {_vm.$set(charge, "amount", $$v)},expression:"charge.amount"}},'money',{
                  decimal: '.',
                  thousands: ',',
                  prefix: _vm.loan.currency == 1 ? '$ ' : 'S/ ',
                  precision: 2,
                  masked: false,
                },false))]}}],null,true)})],1)]),_c('b-col',{attrs:{"cols":"12","md":"5"}},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"font-bureau-style"},[_vm._v("Date")]),_c('ValidationProvider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var errors = ref.errors;
return [_c('b-form-datepicker',{staticClass:"border-hover bg-white-c",class:{ 'border border-danger': errors[0] },attrs:{"placeholder":"Select Date","date-format-options":_vm.formatDate},model:{value:(charge.date),callback:function ($$v) {_vm.$set(charge, "date", $$v)},expression:"charge.date"}})]}}],null,true)})],1)]),_c('b-col',{staticClass:"mt-auto mb-1",attrs:{"cols":"12","md":"2"}},[_c('b-button',{staticClass:"btn-icon rounded-circle",attrs:{"size":"sm","variant":"info","title":"Remove Charge"},on:{"click":function($event){return _vm.removeCharge(index)}}},[_c('feather-icon',{attrs:{"icon":"MinusIcon"}})],1)],1)],1)}),_c('hr')],2)],1),(_vm.modalNewExternalUser)?_c('modal-new-external-user',{on:{"hidden":function($event){_vm.modalNewExternalUser = false},"external-user-saved":_vm.refreshExternalUsers}}):_vm._e(),(_vm.modalNewLoanCategory)?_c('modal-new-loan-category',{on:{"hidden":function($event){_vm.modalNewLoanCategory = false},"loan-category-saved":_vm.refreshLoanCategories}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }