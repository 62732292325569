<template>
  <b-row>
    <b-col v-for="file in files" :key="file.id" cols="12" md="4">
      <div class="d-flex flex-column">
        <div
          class="
            w-100
            d-flex
            flex-column
            align-items-center
            justify-content-center
            mt-50
          "
        >
          <amg-icon icon="CustomFileIcon" class="font-large-4 cursor-pointer" />
          <a class="mt-1 text-center text w-100 h5" :class="textLink" :href="file.url" v-b-tooltip.hover.bottom="file.name" >
            {{ file.name.substr(0,14) }}
          </a>
          <h6>
            {{ file.created_at | myGlobal }}
          </h6>
        </div>
      </div>
    </b-col>
  </b-row>
</template>

<script>
export default {
  props: {
    files: {
      type: Array,
      default: [],
    },
  },
};
</script>

<style>
</style>